
export default {
  name: 'AppLabel',
  props: {
    label: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cssVariables() {
      return {
        '--background': this.label.background_color,
        '--border': this.label.border_color,
        '--text': this.label.text_color,
      };
    },
  },
};
