
export default {
  name: 'AppBanner',
  props: {
    title: {
      type: String,
    },
    label: {
      type: Object,
      default: null,
    },
    backgroundImage: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    description: {
      type: String,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    buttonBackgroundColor: {
      type: String,
      default: null,
    },
    buttonTextColor: {
      type: String,
      default: null,
    },
    buttonLabel: {
      type: String,
    },
    buttonLink: {
      type: String,
      default: '#',
    },
  },
  computed: {
    css() {
      let backgroundImage = encodeURI(this.backgroundImage);

      return {
        '--background-image': `url("${this.$img(
          backgroundImage,
          {},
          { format: 'webp' }
        )}")`,
        '--button-background-color': !!this.buttonBackgroundColor
          ? this.buttonBackgroundColor
          : '#56bc3a',
        '--button-text-color': !!this.buttonTextColor
          ? this.buttonTextColor
          : '#fff',
        '--background-color': !!this.backgroundColor
          ? this.backgroundColor
          : '',
      };
    },
  },
};
